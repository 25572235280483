// Analytics service to handle PostHog and GTM/GA4

// Replace the existing PostHog initialization with this lazy loading approach
let posthog = null;

const initPostHog = async () => {
  if (!posthog) {
    try {
      const PostHog = await import('posthog-js');
      posthog = PostHog.default;
      posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com',
        loaded: (ph) => {
          if (process.env.NODE_ENV === 'development') {
            ph.opt_out_capturing();
          }
        },
        autocapture: false,
        capture_pageview: false,
        persistence: 'localStorage'
      });
    } catch (error) {
      console.warn('PostHog failed to load:', error);
      return {
        capture: () => {},
        identify: () => {}
      };
    }
  }
  return posthog;
};

const Analytics = {
  page: async ({ path, title }) => {
    // Push to PostHog
    const ph = await initPostHog();
    if (ph) {
      ph.capture('$pageview', {
        $current_url: path,
        page_title: title
      });
    }

    // Push to GTM/GA4
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_location: path,
      page_title: title,
      send_to: 'GTM-5CVFWCDD' // Add your GTM container ID
    });
  },

  track: async (event, properties = {}) => {
    // Push to PostHog
    const ph = await initPostHog();
    if (ph) {
      ph.capture(event, properties);
    }

    // Push to GTM/GA4
    window.dataLayer = window.dataLayer || [];
    
    // Format properties for GA4
    const ga4Event = {
      event: event,
      event_category: properties.category || 'All',
      event_label: properties.label || '',
      value: properties.value || 0,
      send_to: 'GTM-5CVFWCDD', // Add your GTM container ID
      ...properties
    };

    // Remove undefined or null values
    Object.keys(ga4Event).forEach(key => {
      if (ga4Event[key] === undefined || ga4Event[key] === null) {
        delete ga4Event[key];
      }
    });

    window.dataLayer.push(ga4Event);

    // Special handling for ecommerce events
    if (event === 'checkout_completed' || event === 'purchase') {
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: properties.currency || 'USD',
          value: properties.value,
          items: properties.items || [],
          transaction_id: properties.transaction_id,
          send_to: 'GTM-5CVFWCDD' // Add your GTM container ID
        }
      });
    }
  },

  identify: async (id, traits = {}) => {
    // Push to PostHog
    const ph = await initPostHog();
    if (ph) {
      ph.identify(id, traits);
    }

    // Push to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'identify',
      user_id: id,
      ...traits
    });
  }
};

export default Analytics; 